body {
  font-family: 'Open Sans';
}

p,
.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.container {
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -180px;

  .center-locator {
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    h1 {
      font-family: 'Poiret One';
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .links {
    list-style-type: none;
    padding-left: 0px;

    li {
      a, a:link, a:visited, a:active {
        color: #888;
        text-decoration: none;
      }

      a:hover {
        color: #000;
      }
    }
  }

  .homepage-links li {
    display: block;

    a {
      display: block;
    }
  }

  .homepage-links li::first-letter {
    font-size: 150%;
    color: #555;
  }

  .homepage-links li:hover::first-letter {
    color: #000;
  }

  .social-links li {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;

    a {
      display: inline-block;
      font-size: 150%;
    }
  }

  .bio-links li a {
    display: inline-block;
    font-size: 150%;
  }
}

br.clear {
  line-height: 0;
}

.bio-navigation {
  margin-top: 40px;
}

.bold {
  font-weight: 700;
}

a.text {
  color: black;
  cursor: text;
}

a:link.text,
a:visited.text,
a:hover.text,
a:active.text {
  color: black;
  text-decoration: none;
}

a.color-black,
a:link.color-black,
a:visited.color-black,
a:hover.color-black,
a:active.color-black {
  color: black;
}

ul.no-bullet,
ol.no-bullet {
  list-style-type: none;
  margin-left: -40px;
}

div.input-group-addon a {
  display:block;
  line-height: 100%;
  height: 100%;
  width: 100%;
}

.music-list-detail {
  margin-top: 50px;
  display: block;

  img.border-none {
    border: none;
  }
}

.music-list-detail .music-list-detail-title {
  text-align: left;
}

.music-list-detail .music-list-detail-audio {
  width: 100%;
}