/* Open Sans */

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(../../font/common/Open_Sans/Open_Sans_Light_Latin_Ext.woff2) format('woff2'), url(../../font/common/Open_Sans/Open_Sans_Light.woff) format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(../../font/common/Open_Sans/Open_Sans_Light_Latin.woff2) format('woff2'), url(../../font/common/Open_Sans/Open_Sans_Light.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(../../font/common/Open_Sans/Open_Sans_Latin_Ext.woff2) format('woff2'), url(../../font/common/Open_Sans/Open_Sans.woff) format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(../../font/common/Open_Sans/Open_Sans_Latin.woff2) format('woff2'), url(../../font/common/Open_Sans/Open_Sans.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../../font/common/Open_Sans/Open_Sans_Bold_Latin_Ext.woff2) format('woff2'), url(../../font/common/Open_Sans/Open_Sans_Bold.woff) format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../../font/common/Open_Sans/Open_Sans_Bold_Latin.woff2) format('woff2'), url(../../font/common/Open_Sans/Open_Sans_Bold.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

/* Poiret One */
/* latin-ext */
@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  src: local('Poiret One'), local('PoiretOne-Regular'), url(../../font/common/Poiret_One/Poiret_One_Latin_Ext.woff2) format('woff2'), url(../../font/common/Poiret_One/Poiret_One.woff) format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  src: local('Poiret One'), local('PoiretOne-Regular'), url(../../font/common/Poiret_One/Poiret_One_Latin.woff2) format('woff2'), url(../../font/common/Poiret_One/Poiret_One.woff) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}